import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Title70, Body20, Title26 } from 'components/Typography'
import { Spacing20 } from 'styles/sharedStyle'
import { bp } from 'styles/breakpoints'
import trackGTM from 'utils/trackGTM'
import GA4 from 'utils/GA4'
import * as Scroll from 'react-scroll'

import {
  HeroWrapper,
  HeroTitle,
  HeroText,
  NavigationItem,
  HeroContainer,
  ItemText,
  NavContentContainer,
  NavContainer,
  ItemBackgroundImage,
  ItemImage,
  ItemBackgroundOpactiy,
} from './GiftGuidesHero.style'

const GiftGuidesHero = ({
  title,
  description,
  hexValue,
  content,
  villageName,
  isModalOpen,
  location,
  numberOfNavigationalItems,
}) => {
  const navContainerRef = useRef(null)
  const contRef = useRef(null)
  const [size, setSize] = useState(null)
  const [minYOffset, setMinYOffset] = useState(540)
  const [isNavOverlowing, setisNavOverlowing] = useState(null)
  const [scrollOffset, setScrollOffset] = useState(null)
  const [isNavFixed, setIsNavFixed] = useState(false)
  const [activeCategory, setActiveCategory] = useState(null)
  const [hasScrolledDown, setHasScrolledDown] = useState(false)
  const { scroller } = Scroll

  // Display max of 8 navigation items
  const navItems = content
    .filter(
      (item) =>
        item.__typename === 'ContentfulCompGiftGuideCatGft01' ||
        item.__typename === 'ContentfulCompGiftGuideProductsGft02'
    )
    .splice(0, numberOfNavigationalItems || 8)

  const updateWidth = () => {
    setSize(window.innerWidth)
    setMinYOffset(window.innerWidth > parseInt(bp[1], 10) ? 440 : 540)
    if (navContainerRef?.current?.clientWidth < window.innerWidth) {
      setisNavOverlowing(false)
    } else {
      setisNavOverlowing(true)
    }
  }

  const updateScroll = () => {
    setScrollOffset(window.pageYOffset)
    setHasScrolledDown(scrollOffset < window.pageYOffset)
    if (!isModalOpen) {
      setIsNavFixed(scrollOffset > minYOffset)
    }
  }

  useEffect(() => {
    updateWidth()
    setScrollOffset(window.pageYOffset)
    if (location?.hash && content) {
      const decodedHash = decodeURI(location.hash)
      const scrollToSection = content
        .filter(
          (item) =>
            item.__typename === 'ContentfulCompGiftGuideCatGft01' ||
            item.__typename === 'ContentfulCompGiftGuideProductsGft02'
        )
        .find(
          (item) =>
            `#${item.title.replace(/\s+/g, '-').toLowerCase()}` === decodedHash
        )
      setTimeout(() => {
        scroller.scrollTo(scrollToSection.id, { offset: -150, smooth: true })
      }, 1000)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', updateWidth)

    return () => window.removeEventListener('resize', updateWidth)
  }, [size, isNavOverlowing])

  useEffect(() => {
    window.addEventListener('scroll', updateScroll)

    return () => window.removeEventListener('scroll', updateScroll)
  }, [scrollOffset, isNavFixed])

  const onSetActive = (item, index, contentLength) => {
    setActiveCategory(item.id)
    window.history.pushState(
      null,
      null,
      `#${item.title.replace(/\s+/g, '-').toLowerCase()}`
    )
    if (contRef.current) {
      contRef.current.scrollTo({
        left:
          (index * navContainerRef.current.clientWidth) / contentLength.length,
        behavior: 'smooth',
      })
    }
  }

  const renderItem = (item, index, contentLength) => {
    const image = item.navigationImage
    return (
      <NavigationItem
        activeClass="active"
        to={item.id}
        key={index}
        spy
        smooth
        offset={-200}
        duration={1000}
        isNavFixed={isNavFixed}
        onSetActive={() => onSetActive(item, index, contentLength)}
        isActive={activeCategory === item.id}
        screenWidth={size}
        itemId={item.id}
        onClick={() => {
          trackGTM(
            'Gift Guides',
            'gift guide navigation',
            'category  click',
            item.title
          )
          GA4('gift_guide_category_click', {
            headline: item.title,
            component_headline: title,
            village_name: villageName,
          })
        }}>
        <ItemBackgroundImage
          screenWidth={size}
          isNavFixed={isNavFixed}
          src={image}>
          <ItemBackgroundOpactiy />
          <ItemImage
            image={image?.portrait?.gatsbyImageData}
            altText={image?.altText}
          />
        </ItemBackgroundImage>
        <Title26 as={ItemText} isNavFixed={isNavFixed}>
          {item.title}
        </Title26>
      </NavigationItem>
    )
  }

  return (
    <HeroContainer
      isNavOverlowing={isNavOverlowing}
      isNavFixed={isNavFixed}
      hasNavigation={navItems.length > 1}>
      <HeroWrapper
        minYOffset={minYOffset}
        hexValue={hexValue}
        hasNavigation={navItems.length > 1}>
        <Title70 as={HeroTitle}>{title}</Title70>
        <Spacing20 />
        <Body20 as={HeroText}>{description}</Body20>
      </HeroWrapper>
      {navItems.length > 1 ? (
        <NavContainer
          ref={contRef}
          isNavFixed={isNavFixed}
          isNavOverlowing={isNavOverlowing}
          screenWidth={size}
          hasScrolledDown={hasScrolledDown}>
          <NavContentContainer
            ref={navContainerRef}
            isNavFixed={isNavFixed}
            isNavOverlowing={isNavOverlowing}>
            {navItems &&
              navItems.map((item, index, array) =>
                renderItem(item, index, array)
              )}
          </NavContentContainer>
        </NavContainer>
      ) : null}
    </HeroContainer>
  )
}

GiftGuidesHero.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  hexValue: PropTypes.string,
  content: PropTypes.string,
  isModalOpen: PropTypes.bool,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
  numberOfNavigationalItems: PropTypes.number,
  villageName: PropTypes.string,
}

GiftGuidesHero.defaultProps = {
  title: '',
  description: '',
  hexValue: '',
  content: '',
  isModalOpen: false,
  numberOfNavigationalItems: 8,
  villageName: '',
}

export default GiftGuidesHero
