import styled from 'styled-components'
import theme from 'styles/theme'
import { Link } from 'react-scroll'
import { GatsbyImage } from 'gatsby-plugin-image'

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) => {
    if (props.hasNavigation) {
      return `
        margin-bottom: ${props.isNavFixed ? '200px' : '0px'};

        ${theme.mediaQueries.large} {
          margin-bottom: ${props.isNavFixed ? '300px' : '-100px'};
        }
      `
    }
    return `
      ${theme.mediaQueries.large} {
        margin-bottom: -100px;
      }
    `
  }}
`

export const HeroWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.125rem;
  margin: 0 auto;
  min-height: 300px;
  height: 150vw;
  width: 100%;
  ${(props) =>
    `max-height: ${props.hasNavigation ? `${props.minYOffset}px` : '700px'};`}

  ${(props) => `background-color: #${props.hexValue};`}

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    height: 100vw;
  }
`

export const HeroTitle = styled.div`
  color: ${theme.colors.white};
  text-align: center;
  margin-top: 100px;
`

export const HeroText = styled.div`
  color: ${theme.colors.white};
  text-align: center;
`

export const NavContainer = styled.div`
  z-index: 4;
  background-color: ${theme.colors.white};
  overflow: auto;
  white-space: nowrap;
  width: 100%;
  display: flex;
  scroll-behavior: smooth;
  transition: opacity 0.5s, max-height 0.5s;
  padding: 0;
  overflow-y: hidden;

  ${(props) => `opacity: ${props.isNavOverlowing === null ? '0' : '1'};`}

  ${(props) => `z-index: ${props.isNavFixed ? '120' : '4'};`}

  ${(props) => {
    let maxHeight = 'auto'
    if (!props.hasScrolledDown && props.isNavFixed) {
      maxHeight = '0'
    } else if (props.isNavFixed) {
      maxHeight = '100px'
    }
    return `max-height: ${maxHeight};`
  }}

  ${(props) =>
    `justify-content: ${props.isNavOverlowing ? 'flex-start' : 'center'};`}
  ${(props) => `position: ${props.isNavFixed ? 'fixed' : 'relative'};`}
`

export const NavContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 3;

  ${(props) =>
    `justify-content: ${props.isNavOverlowing ? 'flex-start' : 'center'};`}
`

export const NavigationItem = styled(Link)`
  ${(props) => `width: calc(${props.screenWidth}px / 2 - 10px);`}
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0 20px;

  ${(props) =>
    `border-bottom: ${
      props.isActive
        ? `4px solid ${theme.colors.gray}`
        : `0px solid ${theme.colors.white}`
    };`}

  ${(props) =>
    `${
      props.isNavFixed
        ? `background: ${theme.colors.white};`
        : `background-image: url(${props.backgroundImage});`
    } `}
  ${(props) => `height: ${props.isNavFixed ? '60px' : '300px'};`}

  transition: height 0.8s, border-bottom-color 0.8s;

  ${theme.mediaQueries.small} {
    width: calc(600px / 3);
    ${(props) => `height: ${props.isNavFixed ? '60px' : '300px'};`}
  }

  ${theme.mediaQueries.medium} {
    width: calc(900px / 3);
    ${(props) => `height: ${props.isNavFixed ? '60px' : '450px'};`}
  }

  ${theme.mediaQueries.large} {
    width: calc(1200px / 4);
    ${(props) => `height: ${props.isNavFixed ? '60px' : '450px'};`}
  }

  ${theme.mediaQueries.xlarge} {
    width: calc(1800px / 5);
    ${(props) => `height: ${props.isNavFixed ? '60px' : '450px'};`}
  }
`

export const ItemBackgroundImage = styled.div`
  ${(props) => `width: calc(${props.screenWidth}px / 2 - 10px);`}
  ${(props) =>
    `height: ${props.isNavFixed ? '60px' : '300px'};`}
  position: absolute;

  ${(props) => `opacity: ${props.isNavFixed ? '0' : '1'};`}

  transition: opacity 0.5s, height 0.8s;

  ${theme.mediaQueries.small} {
    width: calc(600px / 3);
    ${(props) => `height: ${props.isNavFixed ? '60px' : '300px'};`}
  }

  ${theme.mediaQueries.medium} {
    width: calc(900px / 3);
    ${(props) => `height: ${props.isNavFixed ? '60px' : '450px'};`}
  }

  ${theme.mediaQueries.large} {
    width: calc(1200px / 4);
    ${(props) => `height: ${props.isNavFixed ? '60px' : '450px'};`}
  }

  ${theme.mediaQueries.xlarge} {
    width: calc(1800px / 5);
    ${(props) => `height: ${props.isNavFixed ? '60px' : '450px'};`}
  }
`

export const ItemImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
`

export const ItemBackgroundOpactiy = styled.div`
  background-color: ${theme.colors.black};
  opacity: 0.4;
  width: 100%;
  height: 100%;
  z-index: 4;
  position: absolute;
`

export const ItemText = styled.div`
  ${(props) =>
    `color: ${props.isNavFixed ? theme.colors.gray : theme.colors.white};`}
  ${(props) =>
    props.isNavFixed
      ? theme.textStyles.body12Uppercase
      : theme.textStyles.title26}
  ${(props) => `font-size: ${props.isNavFixed ? '12px' : '26px'};`}
  text-align: center;
  z-index: 5;
  white-space: normal;
  width: 200px;
  overflow-wrap: break-word;
`

export default {
  HeroWrapper,
  HeroTitle,
  HeroText,
  NavigationItem,
  HeroContainer,
  ItemText,
  NavContentContainer,
  ItemBackgroundImage,
  ItemBackgroundOpactiy,
}
