/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import GiftGuidesHero from 'components/GiftGuidesHero'
import { TopPaddedPage, ReducedTopPaddedPage } from 'styles/sharedStyle'
import renderContent from 'utils/componentRenderer'
import { decorateListicleContent, groupContentTypes } from 'utils/contentUtils'
import ModalContext from 'components/Modal/context/Modal.context'

const GiftGuidesPageTemplateT16 = (props) => {
  const {
    data: {
      page: {
        content,
        village,
        pageTitle,
        pageDescription,
        hexValue,
        numberOfNavigationalItems,
      },
    },
    pageContext: { pageTypeId, pageLevel },
    location,
  } = props

  const { isModalOpen } = useContext(ModalContext)

  const processedContent = content
    ? decorateListicleContent(groupContentTypes(content))
    : []

  const ContentWrapper =
    pageTypeId === 'map' ? ReducedTopPaddedPage : TopPaddedPage

  return (
    <Layout {...props}>
      <GiftGuidesHero
        title={pageTitle}
        description={pageDescription}
        hexValue={hexValue}
        content={content}
        villageName={
          pageLevel === 'collection' ? 'The Bicester Collection' : village?.name
        }
        isModalOpen={isModalOpen}
        location={location}
        numberOfNavigationalItems={numberOfNavigationalItems}
      />
      <ContentWrapper>{renderContent(processedContent, props)}</ContentWrapper>
    </Layout>
  )
}

GiftGuidesPageTemplateT16.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export const giftGuidesPageT16Query = graphql`
  query(
    $id: String!
    $openingHoursId: String
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
    $collectionId: String
    $pageTypeSlug: String
    $villageSlug: String
    $needContactIcons: Boolean = false
  ) {
    page: contentfulPageTemplateGiftGuidesT16(id: { eq: $id }) {
      locale: node_locale
      content {
        __typename
        ... on Node {
          ...multipleComponentsT16
        }
      }
      village {
        name
        code
        villageSlug: slug
        currency
        servicesHeader {
          ...header
        }
        home: page_template_home_t01 {
          ...headerFooterVillage
        }
        labels: label_brand_details_lab01 {
          readMoreLabel
          readLessLabel
          contactDetailsHeader
          ...virtualShoppingIconsAndLabelsQuery
          onlineLabel
          viewOnMapLabel
        }
        defaultLocale
        openingStatusLabel
      }
      pageTitle: title
      pageDescription: description
      hexValue
      hideFromSearchEngine
      numberOfNavigationalItems
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          ...contentfulOpeningHoursExceptions
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    collectionPage: contentfulPageTemplateHomeT01(id: { eq: $collectionId }) {
      header {
        ...header
      }
      footer {
        ...footer
      }
    }
    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }
    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }
    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
    allMembershipPopUps: allContentfulCompMembershipPopUp(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
        active: { eq: true }
      }
    ) {
      totalCount
      edges {
        node {
          ...membershipPopUp
        }
      }
    }
  }
`

export default GiftGuidesPageTemplateT16
